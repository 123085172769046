import { useRef, useState } from 'react';
import { Button, ButtonArea, Form, Heading, Text } from '../../../components/base';
import { IPluginPromptData, OperationType } from '../../../libs/models/studio/PluginPromptList';

import PluginCreateModeDtl from './PluginCreateModeDtl';
import PluginCreateModeAPI from './PluginCreateModeAPI';
import { useAlert } from '../../../context/AlertContext';

interface IPluginCreateMode {
  formData: IPluginPromptData;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
  onSave: () => void;
  onPrev: () => void;
  onNext: () => void;
}

const operationTypeOptions = [
  {
    value: OperationType.Memory,
    label: '파일 업로드 및 직접 입력',
    title: '학습 데이터 등록',
    body: '파일을 첨부하거나, 데이터 간편 등록을 통해 학습 데이터를 직접 입력해 보세요.',
    alertTitle: '아직 학습되지 않은 데이터가 있습니다.',
  },
  {
    value: OperationType.Api,
    label: 'API 연동',
    title: 'API 연동',
    body: 'API 연동을 통해 학습 데이터를 입력해 보세요.',
    alertTitle: 'API 데이터가 전부 입력되지 않았습니다.',
  },
];

const PluginCreateMode: React.FC<IPluginCreateMode> = ({ formData, setFormData, onSave, onPrev, onNext }) => {
  const dtlRef = useRef<{ validate: () => boolean }>(null);
  const apiRef = useRef<{ validate: () => boolean }>(null);
  const [activeTab, setActiveTab] = useState<OperationType>(OperationType.Memory);

  const activeOption = operationTypeOptions.find((option) => option.value === activeTab) ?? operationTypeOptions[0];

  const { showAlert } = useAlert();

  const handlePrev = () => {
    onPrev();
  };

  const validate = () => {
    // 두 ref 모두 존재하는지 확인
    if (!apiRef.current || !dtlRef.current) {
      return false;
    }
    const apiValidation = apiRef.current?.validate() ?? false;
    const memoryValidation = dtlRef.current?.validate() ?? false;
    return memoryValidation && apiValidation;
  };

  const validateCurrentTab = () => {
    if (activeTab === OperationType.Memory) {
      return dtlRef.current?.validate() ?? false;
    } else if (activeTab === OperationType.Api) {
      return apiRef.current?.validate() ?? false;
    }
    return false;
  };
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      onNext();
    } else {
      showAlert({
        title: '입력이 완료되지 않은 항목이 있습니다.',
        message: '다음 단계로 넘어가시겠습니까?',
        confirmLabel: '다음 단계로',
        showCancel: true,
        onConfirm: () => {
          onNext();
        },
      });
    }
  };
  const handleTabChange = (newTab: OperationType) => {
    if (activeTab !== newTab) {
      const isValid = validateCurrentTab();

      if (!isValid) {
        showAlert({
          title: activeOption.alertTitle,
          message: '탭을 변경하시겠습니까?',
          confirmLabel: '변경',
          showCancel: true,
          onConfirm: () => {
            setActiveTab(newTab);
            onSave();
          },
        });
      } else {
        setActiveTab(newTab);
        onSave();
      }
    }
  };

  return (
    <>
      <div className="dsx-side-left operation-tab ">
        {operationTypeOptions.map((option) => (
          <Button
            key={option.value}
            variant={activeTab === option.value ? 'normal' : 'outline'}
            onClick={() => {
              handleTabChange(option.value);
            }}
          >
            {option.label}
          </Button>
        ))}
      </div>

      <div className="dsx-title-bar">
        <Heading as="h2" size="title3" weight="semibold">
          {activeOption.title}
        </Heading>
        <Text size="body2"> {activeOption.body}</Text>
      </div>
      <Form
        variant="vertical"
        className={`form-field operation-form ${activeTab === OperationType.Memory ? '' : 'hidden'}`}
        onSubmit={handleFormSubmit}
      >
        {/* ref 살아있어야해서, 항상 탭 2개를 렌더링 */}
        <PluginCreateModeDtl ref={dtlRef} formData={formData} setFormData={setFormData} />
      </Form>
      <Form
        variant="vertical"
        className={`form-field operation-form ${activeTab === OperationType.Api ? '' : 'hidden'}`}
        onSubmit={handleFormSubmit}
      >
        <PluginCreateModeAPI ref={apiRef} formData={formData} setFormData={setFormData} />
      </Form>

      <ButtonArea align="end">
        <div className="dsx-side-left">
          <Button variant="outline" size="large" prefixIcon="chevronLeft" onClick={handlePrev}>
            이전
          </Button>
        </div>
        <Button type="submit" variant="primary" size="large" suffixIcon="chevron" onClick={handleFormSubmit}>
          다음
        </Button>
      </ButtonArea>
    </>
  );
};

export default PluginCreateMode;
