import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { AuthorityType, IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import PluginCreateProfile from './PluginCreateProfile';
import PluginCreateReg from './PluginCreateReg';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import PluginCreateMode from './PluginCreateMode';

const steps = ['프로필 생성', '데이터 학습', '배포'];

interface PluginCreateFormProps {
  formData: IPluginPromptData;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
  onSubmit: () => void;
  handleSave: (arg0: string) => void;
}

const PluginCreateForm: FC<PluginCreateFormProps> = ({ formData, setFormData, onSubmit, handleSave }) => {
  const { user } = useAppSelector((state: RootState) => state.users);
  //const authority = formData.managedMembers?.filter(m => m.user.userId == user.userId)[0]?.authority ?? AuthorityType.Owner;
  const [auth, setAuth] = useState<AuthorityType>(AuthorityType.Owner);
  const [step, setStep] = useState<number>(formData.createdStep ?? 1);
  const [isTabSelectable, setIsTabSelectable] = useState(false);

  useEffect(() => {
    const tabSelectableValue =
      formData.name && formData.model && formData.profileUrl && formData.description && formData.pluginCategory;

    if (tabSelectableValue ?? step > 1) {
      setIsTabSelectable(true);
    }
  }, [step, formData]);

  // useEffect(() => {
  //   const tabSelectableValue =
  //     formData.name && formData.model && formData.profileUrl && formData.description && formData.pluginCategory;

  //   if (!tabSelectableValue) {
  //     // 1step에서 하나라도 없는게 있을때
  //     setIsTabSelectable(false);
  //     dispatch(
  //       showAlertToast({
  //         altMessage: '필수값을 입력해주세요',
  //         altType: AlertType.Warning,
  //       }),
  //     );
  //   } else if (formData.operationType === OperationType.Api && step == 2) {
  //     // 외부api인데 2단계에서 필수값이 없을떄

  //     if (Array.isArray(formData.openAPIs)) {
  //       console.log('실행');
  //       const hasValidAPIs = formData.openAPIs.every((item) => item.apiName && item.jsonData);
  //       if (!hasValidAPIs) {
  //         setIsTabSelectable(false);
  //         dispatch(
  //           showAlertToast({
  //             altMessage: '필수값을 입력해주세요',
  //             altType: AlertType.Warning,
  //           }),
  //         );
  //       } else {
  //         setIsTabSelectable(true);
  //       }
  //     }
  //   } else {
  //     setIsTabSelectable(true);
  //   }
  // }, [step, formData]);

  useEffect(() => {
    const auth = formData.managedMembers?.find((m) => m.user.userId == user.userId);
    if (auth) {
      setAuth(auth.authorityType);
    } else {
      setAuth(AuthorityType.Contributor);
    }
  }, [user, formData]);

  useEffect(() => {
    if (formData.createdStep && formData.createdStep !== step) {
      setStep(formData.createdStep);
    }
  }, [formData.createdStep, step]);

  const updateFormDataWithStep = (newStep: number) => {
    setStep(newStep);
    if (formData.pluginCategoryId) {
      setFormData((prev) => ({
        ...prev,
        createdStep: newStep,
      }));
      handleSave('temp'); // Save as temporary
    }
  };

  const handleNext = () => {
    updateFormDataWithStep(step + 1);
  };

  const handlePrev = () => {
    if (step > 1) {
      updateFormDataWithStep(step - 1);
    }
  };

  const handleTabSelect = (index: number) => {
    if (isTabSelectable) {
      setStep(index + 1);
      setFormData((prev) => ({ ...prev, createdStep: index + 1 }));
      handleSave('temp'); // Save as temporary
    }
  };

  useEffect(() => {
    if (formData.createdStep && formData.createdStep !== step) {
      setStep(formData.createdStep);
    }
  }, [formData.createdStep, step]);

  // 241227 주석
  // const handleTabSelect = (index: number) => {
  //   const tabSelectableValue =
  //     formData.name && formData.model && formData.profileUrl && formData.description && formData.pluginCategory;

  //   if (!tabSelectableValue) {
  //     // 1step에서 하나라도 없는게 있을때
  //     setIsTabSelectable(false);
  //     dispatch(
  //       showAlertToast({
  //         altMessage: '필수값을 입력해주세요',
  //         altType: AlertType.Warning,
  //       }),
  //     );
  //     return; // 유효하지 않으면 함수 종료
  //   }

  //   // if (formData.operationType === OperationType.Api && step === 2) {
  //   //   if (index != 0) // 1단계로 이동은 허용
  //   //     if (Array.isArray(formData.openAPIs)) {
  //   //       // 외부api인데 2단계에서 필수값이 없을때
  //   //       const hasValidAPIs = formData.openAPIs.every((item) => item.apiName && item.jsonData);
  //   //       if (!hasValidAPIs) {
  //   //         setIsTabSelectable(false);
  //   //         dispatch(
  //   //           showAlertToast({
  //   //             altMessage: '필수값을 입력해주세요',
  //   //             altType: AlertType.Warning,
  //   //           }),
  //   //         );
  //   //         return; // 유효하지 않으면 함수 종료
  //   //       }
  //   //     }
  //   // }

  //   // 모든 체크를 통과한 경우
  //   setIsTabSelectable(true);
  //   setStep(index + 1);
  //   setFormData((prev) => ({ ...prev, createdStep: index + 1 }));
  //   handleSave('temp'); // Save as temporary
  // };

  return (
    <>
      <div className="form-step">
        <ol className="step-list" role="img" aria-label={`총 ${steps.length}단계 중 ${step}단계 ${steps[step - 1]}`}>
          {steps.map((stepName, index) => (
            <li
              key={index}
              className={classNames(
                index + 1 < step ? 'is-complete' : '',
                index + 1 === step ? 'is-active' : '',
                isTabSelectable ? 'selectable' : '',
              )}
              onClick={() => {
                handleTabSelect(index);
              }}
            >
              <span className="step-name">{stepName}</span>
            </li>
          ))}
        </ol>
      </div>
      <div className="form-panel">
        {step === 1 && <PluginCreateProfile formData={formData} setFormData={setFormData} onNext={handleNext} />}
        {step === 2 && (
          <PluginCreateMode
            formData={formData}
            setFormData={setFormData}
            onSave={() => {
              handleSave('temp');
            }}
            onPrev={handlePrev}
            onNext={handleNext}
          />
        )}

        {step === 3 && (
          <PluginCreateReg
            formData={formData}
            authority={auth}
            setFormData={setFormData}
            onPrev={handlePrev}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </>
  );
};

export default PluginCreateForm;
