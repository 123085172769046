import { useState } from 'react';
import {
  Form,
  FormItem,
  Select,
  Input,
  Textarea,
  ButtonArea,
  Button,
  Avatar,
  Tooltip,
  Heading,
  Text,
  Mark,
  Badge,
} from '../../../components/base';
import PluginPromptCreateDialog from '../PluginPromptCreateDialog';
import { IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';

interface Errors {
  pluginCategoryId: string;
  name: string;
  description: string;
  profileUrl: string;
  model: string;
  operationType?: string;
}

interface IPluginCreateProfile {
  formData: IPluginPromptData;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
  onNext: () => void;
}

const PluginCreateProfile: React.FC<IPluginCreateProfile> = ({ formData, setFormData, onNext }) => {
  const { categoryList } = useAppSelector((state: RootState) => state.category);
  const { models } = useAppSelector((state: RootState) => state.models);
  const [dialogOpen, setDialogOpen] = useState(false);

  const modelOptions = Object.keys(models).map((model) => ({
    value: model,
    label: model,
  }));

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const [errors, setErrors] = useState<Errors>({
    pluginCategoryId: '',
    name: '',
    description: '',
    profileUrl: '',
    model: '',
  });

  const validate = () => {
    const newErrors = {
      pluginCategoryId: '',
      name: '',
      description: '',
      profileUrl: '',
      model: '',
    };
    if (!formData.profileUrl) newErrors.profileUrl = '이미지를 선택해주세요.';
    if (!formData.pluginCategoryId || formData.pluginCategoryId === 'all')
      newErrors.pluginCategoryId = '카테고리명을 선택해주세요.';
    if (!formData.name) newErrors.name = '플러그인 명을 입력해주세요.';
    if (!formData.description) newErrors.description = '소개를 입력해주세요.';
    if (!formData.model) newErrors.model = '모델을 선택해주세요.';
    setErrors(newErrors);
    return Object.values(newErrors).every((value) => value.trim().length === 0);
  };

  // 24.10.30 실행되고 있지 않은 함수
  // const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0]; // optional chaining을 사용하여 null 체크
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setFormData((prev) => ({ ...prev, profileUrl: reader.result as string }));
  //       setErrors((prevErrors) => ({ ...prevErrors, profileUrl: '' }));
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setErrors((prevErrors) => ({ ...prevErrors, profileUrl: '이미지를 선택해주세요.' }));
  //   }
  // };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? '' : prevErrors[name as keyof Errors],
    }));
  };

  const handleSelectChange = (selectedOption: string | number) => {
    setFormData((prev) => ({ ...prev, pluginCategoryId: selectedOption as string }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      pluginCategoryId: selectedOption ? '' : prevErrors.pluginCategoryId,
    }));
  };

  const handleModelChange = (selectedOption: string | number) => {
    setFormData((prev) => ({ ...prev, model: selectedOption as string }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      model: selectedOption ? '' : prevErrors.model,
    }));
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      onNext();
    }
  };

  return (
    <>
      <div className="form-avatar">
        <Avatar
          className={`${dialogOpen}`}
          as="button"
          size="9"
          variant={formData.profileUrl ? 'default' : 'empty'}
          src={
            formData.profileUrl &&
            `${formData.profileUrl}?v=${formData.profileUrlVersion ? formData.profileUrlVersion : ''}`
          }
          label={formData.profileUrl ? '플러그인 프로필 이미지' : '이미지는 필수로 등록해주세요'}
          accent="normal"
          badgeProps={{
            icon: 'plus',
            label: '프로필 이미지 등록',
          }}
          // onChange={handleAvatarChange}
          invalid={!!errors.profileUrl}
          onClick={handleDialogOpen}
        />
      </div>

      {/* 프로필 이미지 등록 팝업 */}
      <PluginPromptCreateDialog
        isOpen={dialogOpen}
        onClose={handleDialogClose}
        setFormData={setFormData}
        formData={formData}
      />
      {/* //프로필 이미지 등록 팝업 */}

      <Form variant="vertical" className="form-field" column={2} onSubmit={handleFormSubmit}>
        <FormItem label="카테고리" error={errors.pluginCategoryId} isRequired>
          <Select
            variant="filled"
            size="xlarge"
            placeholder="카테고리명"
            name="pluginCategoryId"
            value={formData.pluginCategoryId as string}
            options={categoryList}
            onChange={handleSelectChange}
            full
          />
        </FormItem>
        <FormItem label="플러그인명" error={errors.name} isRequired>
          <Input
            variant="filled"
            size="xlarge"
            placeholder="플러그인 이름을 입력하세요"
            maxLength={16}
            showCount
            name="name"
            value={formData.name as string}
            onChange={handleChange}
            full
          />
        </FormItem>
        <FormItem label="소개" error={errors.description} isRequired className="dsx-w-full">
          <Textarea
            variant="filled"
            size="xlarge"
            placeholder="플러그인 기능에 대한 소개를 적어주세요"
            rows={4}
            maxLength={300}
            showCount
            name="description"
            value={formData.description as string}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem
          label="페르소나/지침"
          info={
            <Tooltip
              size="large"
              position={['bottom', 'end']}
              triggerEvent="click"
              className="form-tooltip"
              message={
                <>
                  <div className="tooltip-head">
                    <Heading size="body1">페르소나 / 지침</Heading>
                    <Text>
                      AI 플러그인의 행동, 언어, 태도 등을 일관되게
                      <br /> 유지하는데 중요한 요소입니다.
                      <br /> 아래 내용들을 고려하여 작성해주세요.
                    </Text>
                  </div>
                  <div className="tooltip-body dsx-text-left">
                    <Text size="body3" weight="medium">
                      <Badge variant="filled" size="small" accent="primary" iconOnly="check" round />
                      어떤 <Mark accent="primary">목적</Mark>으로 쓰이나요?
                    </Text>
                    <Text size="body3" weight="medium">
                      <Badge variant="filled" size="small" accent="primary" iconOnly="check" round />
                      어떤 <Mark accent="primary">대화 스타일/언어 사용</Mark>을 원하시나요?
                    </Text>
                    <Text size="body3" weight="medium">
                      <Badge variant="filled" size="small" accent="primary" iconOnly="check" round />
                      주요 <Mark accent="primary">사용자군</Mark>은 누구인가요?
                    </Text>
                    <Text size="body3" weight="medium">
                      <Badge variant="filled" size="small" accent="primary" iconOnly="check" round />
                      질문한<Mark accent="primary"> 사용자</Mark> 정보가 필요하신가요?
                      <br /> <Mark accent="primary"> ||이름||, ||사번||, ||부서명||</Mark> 을 입력해보세요
                    </Text>
                  </div>
                </>
              }
            />
          }
          className="dsx-w-full"
        >
          <Textarea
            variant="filled"
            size="xlarge"
            placeholder="이 GPT가 무엇을 하는지, 어떻게 작동하는지, 무엇을 피해야 하는지 설명을 추가하세요."
            rows={4}
            maxLength={1000}
            showCount
            name="persona"
            value={formData.persona as unknown as string}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem label="사용모델" className="dsx-w-full" error={errors.model} isRequired>
          <Select
            variant="filled"
            size="xlarge"
            placeholder="사용모델"
            name="model"
            value={formData.model as string}
            options={modelOptions}
            onChange={handleModelChange}
            full
          />
        </FormItem>
      </Form>
      <ButtonArea align="end">
        <Button type="submit" variant="primary" size="large" suffixIcon="chevron" onClick={handleFormSubmit}>
          다음
        </Button>
      </ButtonArea>
    </>
  );
};

export default PluginCreateProfile;
