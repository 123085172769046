import { UserData } from '../users/UsersState';
import { IPluginPromptCategoryData } from './Category';

export enum AuthorityType {
  Owner = 'Owner',
  Contributor = 'Contributor',
}
export enum OperationType {
  Auto = 'Auto',
  Memory = 'Memory',
  Api = 'Api',
  AiOnU = 'AiOnU',
}

export enum EOpenScopeType {
  Public = 'Public',
  Private = 'Private',
}

export enum ECreatedStatusType {
  Registration = 0, // 등록중
  Learning = 1, // 학습중
  LearningComplete = 2, // 학습완료
  Complete = 3, // 완료
}

export enum EVersionStatusType {
  Draft = 0, // 임시저장
  Creat = 1, // 최초
  Update = 2, // 업데이트버전
}

export enum APIAuthPos {
  Header = 'Header',
  Query = 'Query',
}

export interface IPluginPromptData {
  pluginCategory?: IPluginPromptCategoryData;
  promptCategory?: IPluginPromptCategoryData;
  ragContentsData?: RagContentsData | null;
  ragContentsDataCount?: number | null;
  conversationStarters?: string[];
  conversationStarterCount?: number | null;
  bookmarkUserCount?: number | null;
  companyCode?: string | null;
  pluginCategoryId?: string;
  promptCategoryId?: string;
  name?: string | null;
  description?: string | null;
  model?: string | null;
  profileUrl?: string | null;
  profileUrlVersion?: number | null;
  openScope?: EOpenScopeType;
  persona?: null;
  customPersona?: string;
  ragFileIds?: string[];
  ragFiles?: IFileData[];
  ragContentsDataId?: string;
  conversationStarterIds?: string[];
  bookmarkUserIds?: string[];
  enabled?: boolean;
  createdStatus?: ECreatedStatusType | keyof typeof ECreatedStatusType;
  versionStatus?: EVersionStatusType | keyof typeof EVersionStatusType;
  createdStep?: number | null;
  createdMember?: UserData | null;
  managedMembers?: ManagedMember[] | null;
  deletedTime?: Date | null;
  tags?: string[];
  type?: string | null;
  id?: string | null;
  partitionKey?: string | null;
  updatedTime?: Date | null;
  createdTime?: Date | null;
  deleted?: boolean | null;
  promptTemplate?: string;
  promptTemplateResponse?: string;
  inputOutput?: IinputOutput[] | null;
  message?: string;
  hidden?: boolean;
  operationType?: OperationType;
  openAPIs?: IOpenAPIItem[] | null;
}

////export type ManagedMember = Record<AuthorityType, UserData>;
export interface ManagedMember {
  authorityType: AuthorityType;
  user: UserData;
}
////export type ManagedMember = Record<AuthorityType, UserData>;
export interface ManagedMember {
  authorityType: AuthorityType;
  user: UserData;
}
////export type ManagedMember = Record<AuthorityType, UserData>;
export interface ManagedMember {
  authorityType: AuthorityType;
  user: UserData;
}

export interface ICreatedMember {
  companyCode?: string | null;
  userId?: string | null;
  fullName?: string | null;
}

export interface IPluginPromptAlertMessage {
  id: string;
  name: string;
  // category: IPluginPromptCategoryData;
  description: string | null;
  likes: number;
  comments: number;
}

export interface IinputOutput {
  companyCode?: string | null;
  createdTime?: string | null;
  deleted?: boolean | null;
  id?: string | null;
  inPut?: string | null;
  outPut?: string | null;
  partitionKey?: string | null;
  type?: string | null;
  updatedTime?: string | null;
  customPluginId?: string | null;
  message?: string | null;
  hasError?: boolean;
  status?: string;
}

export interface FileType {
  name: string;
  size: number;
  type: string;
  status: 'uploading' | 'uploaded' | 'uploadFailed' | 'learning' | 'learningSuccess' | 'learningFailed';
  originalFile?: File;
}

export interface IOpenAPIItem {
  frontId: number;
  apiName: string;
  authType?: string;
  authPos?: string;
  apiKey?: string;
  apiValue?: string;
  jsonData: string;
  isExpanded: boolean;
}

export interface IPluginPromptParam {
  categoryId?: string | null;
  openScope?: 0 | 1 | null;
  createdStatus?: 0 | 1 | 2 | 3 | null;
  versionStatus?: 0 | 1 | 2 | null;
  isDeleted?: boolean | null;
  orderBy?: string | null;
  orderDirection?: string | null;
  isBookmark?: boolean | null;
  isMy?: boolean | null;
  keyWord?: string | null;
  count?: number;
  continuationToken?: string | null;
  inputOutput?: IinputOutput[] | null;
  [key: string]: string | number | boolean | null | IinputOutput[] | undefined;
}

export interface IPluginPromptList {
  continuationToken: string | null;
  data: IPluginPromptData[];
  totalCount: number;
}

export interface IPluginPromptSave {
  value: IPluginPromptData;
  statusCode: number;
}

export interface IPluginFileSave {
  value: IFileData;
  statusCode: number;
}

export interface IPluginContentsSave {
  value: RagContentsData;
  statusCode: number;
}

export interface IinputOutputSave {
  value: IinputOutput;
  statusCode: number;
}

export interface IFileData {
  originalDocumentId: string;
  index: string;
  documentId: string;
  fileName: string;
  notDownloadable: boolean;
  isDrm: boolean;
  isDraft: boolean;
  isCompleted: boolean;
  hasError: boolean;
  created: Date;
  deleteRequested: boolean;
  eTag: string;
  companyCode?: string | null;
  partitionKey: string;
  rowKey: string;
  updatedTime?: string | null;
  timestamp: Date;
  fileSizeLong?: number;
  status: 'Published' | 'AddRequsted' | 'EditRequested' | 'DeleteRequsted' | 'Error';
}

export interface IProfileImg {
  value: string;
  statusCode: number;
}

export interface RagContentsData {
  companyCode?: string | null;
  createdTime?: string | null;
  contentsData?: string;
  deletedRequested?: boolean;
  deleted?: boolean | null;
  id: string;
  contentsDataFileName: string;
  partitionKey?: string | null;
  type?: string | null;
  updatedTime?: string | null;
  customPluginId?: string | null;
  message?: string | null;
  hasError?: boolean;
  isCompleted?: boolean;
  uploadStatus:
    | 'NotStarted' // 업로드 시작 전 상태
    | 'Pending' // 업로드 대기 중
    | 'InProgress' // 업로드 중인 상태
    | 'Completed' // 업로드 완료 상태
    | 'Failed' // 업로드 실패 상태
    | 'Canceled' // 업로드 취소
    | 'Removing' // 삭제 중
    | 'Learning' // 학습 중
    | 'LearningSuccess' // 학습 성공
    | 'LearningFailed' // 학습 실패
    | 'Save' // 저장
    | 'Saving' // 저장중
    | 'Editing'; // 수정중
  status?: 'Published' | 'AddRequsted' | 'EditRequested' | 'DeleteRequsted' | 'Error';
}
