// PluginCreateModeAPI.tsx
import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import {
  FormItem,
  Button,
  Text,
  Heading,
  IconButton,
  Badge,
  Input,
  Select,
  Tooltip,
  Mark,
  RadioGroup,
  InputEditTable,
  InputEditTableHandle,
} from '../../../components/base';
import { APIAuthPos, IOpenAPIItem, IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import { usePlugin } from '../../../libs/hooks/usePlugin';
import MonacoEditor from '@monaco-editor/react';
import { useAppDispatch } from '../../../redux/app/hooks';
import { showAlertToast } from '../../../redux/features/app/appSlice';
import { AlertType } from '../../../libs/models/AlertType';

interface Errors {
  apiName: string;
  jsonData: string;
}

interface IPluginCreateModeAPIProps {
  formData: IPluginPromptData;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
}

const PluginCreateModeAPI = forwardRef<{ validate: () => boolean }, IPluginCreateModeAPIProps>(
  ({ formData, setFormData }, ref) => {
    useImperativeHandle(ref, () => ({
      validate: () => {
        const newErrors = {
          apiName: '',
          jsonData: '',
        };
        const openAPIs = formData.openAPIs; // openAPIs 리스트

        // openAPIs가 배열인지 확인
        if (Array.isArray(openAPIs)) {
          let isValid = true;

          openAPIs.forEach((item) => {
            if (!item.apiName) {
              newErrors.apiName = `API명 입력해주세요.`;
              isValid = false;
            }
            if (!item.jsonData) {
              newErrors.jsonData = `jsonData 입력해주세요.`;
              isValid = false;
            }
          });
          setErrors(newErrors);
          if (!isValid) console.log('api : not validate');

          return isValid;
        } else {
          newErrors.apiName = 'openAPIs는 리스트여야 합니다.';
          console.log('api : not validate');
          setErrors(newErrors);
          return false;
        }
      },
    }));

    const nameRefs = useRef<Record<number, InputEditTableHandle | null>>({});
    // const fileRefs = useRef<Record<number, FileUploaderHandle | null>>({});

    const plugin = usePlugin();
    const dispatch = useAppDispatch();

    const [apiSample, setApiSample] = useState<string>('{}');
    const [isValidJson, setIsValidJson] = useState(true);
    const [apiItems, setApiItems] = useState<IOpenAPIItem[]>([]);

    const authTypeOptions = [
      { value: 'Basic', label: 'Basic' },
      { value: 'Bearer', label: 'Bearer' },
      { value: 'Custom', label: '맞춤형' },
    ];
    const authPosOptions = [
      { value: 'Header', label: 'Header' },
      { value: 'Query', label: 'Query' },
    ];
    useEffect(() => {
      const fetchApiSample = async () => {
        try {
          const data = await plugin.getOpenAPI('example-openapi-json');
          setApiSample(data.jsonData);
          return data.jsonData;
        } catch (error) {
          console.error('Failed to fetch API sample:', error);
          return null;
          // Optional: You can also set an error state here if needed
        }
      };

      const initializeApiItems = () => {
        if (formData.openAPIs && Array.isArray(formData.openAPIs) && formData.openAPIs.length > 0) {
          const updatedApiItems = formData.openAPIs.map((item, index) => ({
            ...item,
            frontId: index + 1, // index + 1로 frontId 부여
            isExpanded: false,
          }));
          setApiItems(updatedApiItems);
          void fetchApiSample();
        }
      };

      initializeApiItems();
    }, []);

    useEffect(() => {
      setFormData((data) => ({ ...data, openAPIs: apiItems }));
    }, [apiItems]);

    const [errors, setErrors] = useState<Errors>({
      apiName: '',
      jsonData: '',
    });

    // 250203 : mode 페이지 추가
    // const handlePrev = () => {
    //   if (onPrev) onPrev();
    // };

    // const handleFormSubmit = (e: React.FormEvent) => {
    //   e.preventDefault();
    //   if (validate()) onNext();
    // };

    const handleDeleteApiItem = (frontId: number) => {
      setApiItems(apiItems.filter((item) => item.frontId !== frontId));

      // if (apiItems.length > 1) {
      //   setApiItems(apiItems.filter((item) => item.frontId !== frontId));
      // }
    };

    const handleAddApiItem = () => {
      if (apiItems.length < 5) {
        const newId = apiItems.length === 0 ? 1 : apiItems[apiItems.length - 1].frontId + 1;
        setApiItems([
          ...apiItems,
          {
            frontId: newId,
            apiName: '',
            apiKey: '',
            jsonData: apiSample,
            isExpanded: true,
          },
        ]);
      }
    };

    const handleToggleApiItem = (frontId: number) => {
      setApiItems(
        apiItems.map((item) => (item.frontId === frontId ? { ...item, isExpanded: !item.isExpanded } : item)),
      );
    };
    const handleAuthTypeChange = (frontId: number) => (selectedOption: string | number) => {
      const authType = String(selectedOption); // Convert to string if needed
      setApiItems(
        apiItems.map((item) =>
          item.frontId === frontId
            ? { ...item, authType: authType, apiKey: authType == 'Custom' ? '' : authType }
            : item,
        ),
      );
    };
    const handleAuthPosChange = (frontId: number) => (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      let authPos: APIAuthPos | undefined;
      switch (value) {
        case 'Header':
          authPos = APIAuthPos.Header;
          break;
        case 'Query':
          authPos = APIAuthPos.Query;
          break;
        default:
          authPos = APIAuthPos.Header;
      }
      setApiItems(apiItems.map((item) => (item.frontId === frontId ? { ...item, authPos: authPos } : item)));
    };
    const handleJsonDataChange = (frontId: number) => (value: string | undefined) => {
      const jsonData = String(value); // Convert to string if needed

      setApiItems(apiItems.map((item) => (item.frontId === frontId ? { ...item, jsonData: jsonData } : item)));

      try {
        JSON.parse(jsonData);
        setIsValidJson(true);
      } catch (e) {
        setIsValidJson(false);
      }
    };

    const handleChange = (frontId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setApiItems(apiItems.map((item) => (item.frontId === frontId ? { ...item, [name]: value } : item)));

      if (name === 'apiName') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: value ? '' : prevErrors[name as keyof Errors],
        }));
      }
    };
    // const handleApiNameChange = (frontId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    //   setApiItems(apiItems.map((item) => (item.frontId === frontId ? { ...item, apiName: e.target.value } : item)));
    // };

    // const handleApiKeyChange = (frontId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    //   setApiItems(apiItems.map((item) => (item.frontId === frontId ? { ...item, apiKey: e.target.value } : item)));
    // };
    // const handleApiValueChange = (frontId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    //   setApiItems(apiItems.map((item) => (item.frontId === frontId ? { ...item, apiValue: e.target.value } : item)));
    // };

    const fileReset = (frontId: number) => {
      setApiItems(apiItems.map((item) => (item.frontId === frontId ? { ...item, jsonData: apiSample } : item)));
    };

    const handleFileReset = (frontId: number) => {
      fileReset(frontId);
    };

    const handleJsonDownload = (frontId: number) => {
      const item = apiItems.find((item) => item.frontId === frontId);

      if (!item?.jsonData) {
        return;
      }
      try {
        const parsedData = JSON.parse(item.jsonData) as JSON;
        const blob = new Blob([JSON.stringify(parsedData, null, 2)], { type: 'application/json' });

        // const jsonString = JSON.stringify(item.jsonData, null, 2);
        // const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;

        a.download = 'download.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } catch (error) {
        dispatch(
          showAlertToast({
            altMessage: '유효한 JSON 형식이 아닙니다. JSON 형식을 확인해주세요.',
            altType: AlertType.Warning,
          }),
        );
      }
    };

    return (
      <>
        <FormItem
          label="API 목록"
          desc="API는 최대 5개 까지 연동할 수 있어요"
          info={
            <>
              <Badge size="large" accent="violet">
                {apiItems.length}
              </Badge>
              <div className="dsx-side-right">
                <Button variant="normal" onClick={handleAddApiItem} disabled={apiItems.length >= 5}>
                  추가하기
                </Button>
              </div>
              {errors.apiName && <Text variant="error">{errors.apiName}</Text>}
              {errors.jsonData && <Text variant="error">{errors.jsonData}</Text>}
            </>
          }
        >
          <div className="learning-list2">
            {apiItems.map((item) => (
              <div key={item.frontId} className={`learning-item ${item.isExpanded ? 'is-active' : ''}`}>
                <div className="item-head">
                  <label htmlFor={`api_name_${item.frontId}`} className="dsx-Label is-required">
                    <span className="label-badge">API명</span>
                  </label>
                  <InputEditTable
                    ref={(el) => {
                      nameRefs.current[item.frontId] = el;
                    }}
                    id={`api_name_${item.frontId}`}
                    name="apiName"
                    value={item.apiName}
                    onChange={handleChange(item.frontId)}
                  />
                  {/* {apiItems.length > 1 && ( */}
                  <div className="item-edit">
                    <IconButton
                      name="delete"
                      size="large"
                      onClick={() => {
                        handleDeleteApiItem(item.frontId);
                      }}
                    >
                      삭제
                    </IconButton>
                  </div>
                  {/* )} */}
                  <IconButton
                    name="chevronBottom"
                    size="large"
                    className="item-control"
                    aria-expanded={item.isExpanded}
                    onClick={() => {
                      handleToggleApiItem(item.frontId);
                    }}
                  >
                    확장
                  </IconButton>
                </div>

                <div className="item-body">
                  <div className="item-data frm-panel">
                    {' '}
                    <div className="frm-bind">
                      <label className="dsx-Label">
                        <span className="label-badge">인증</span>
                      </label>

                      <Select
                        size="xlarge"
                        placeholder="인증타입"
                        value={item.authType}
                        options={authTypeOptions}
                        onChange={handleAuthTypeChange(item.frontId)}
                      />
                    </div>
                    <div className="frm-bind dsx-w-grow">
                      <label htmlFor={`api_key_${item.frontId}`} className="dsx-Label">
                        <span className="label-badge">API 인증 Key</span>
                      </label>
                      {item.authType === 'Custom' && (
                        <Input
                          id={`api_key_${item.frontId}`}
                          size="xlarge"
                          placeholder="API 인증 Key를 입력해주세요"
                          name="apiKey"
                          value={item.apiKey ?? ''}
                          onChange={handleChange(item.frontId)}
                          full
                        />
                      )}
                      <Input
                        id={`api_value_${item.frontId}`}
                        size="xlarge"
                        placeholder="API 인증 Value를 입력해주세요"
                        name="apiValue"
                        value={item.apiValue ?? ''}
                        onChange={handleChange(item.frontId)}
                        full
                      />
                      {item.authType === 'Custom' && (
                        <RadioGroup
                          size=""
                          options={authPosOptions}
                          value={item.authPos}
                          onChange={handleAuthPosChange(item.frontId)}
                        />
                      )}
                    </div>
                    <div className="frm-bind dsx-w-full"></div>
                  </div>
                  <div className="item-data">
                    <div className="dsx-title-bar">
                      <label className="dsx-Label is-required">
                        <Text as="span" size="body3" weight="semibold">
                          JSON 데이터
                        </Text>
                      </label>
                      <div className="dsx-side-right">
                        <Tooltip
                          size="large"
                          position={['bottom', 'end']}
                          triggerEvent="click"
                          className="form-tooltip"
                          message={
                            <>
                              <div className="tooltip-head">
                                <Heading size="body1">외부 API 연동</Heading>
                                <Text>
                                  초기 작성된 값은 테스트용으로 작성된
                                  <br />
                                  <Mark accent="primary">전등 API </Mark> JSON 입니다.
                                  <br />
                                  JSON을 수정해서 API연결을 해주세요
                                </Text>
                              </div>
                              <div className="tooltip-body dsx-text-left">
                                <Text size="body3" weight="medium">
                                  <Badge variant="filled" size="small" accent="primary" iconOnly="check" round />
                                  알맞은 <Mark accent="primary">OpenAPI 스펙</Mark>을 적어주세요
                                </Text>
                                <Text size="body3" weight="medium">
                                  <Badge variant="filled" size="small" accent="primary" iconOnly="check" round />
                                  자세하게 <Mark accent="primary">요청, 응답형식</Mark>을 적어주세요
                                </Text>
                                <Text size="body3" weight="medium">
                                  <Badge variant="filled" size="small" accent="primary" iconOnly="check" round />
                                  질문한<Mark accent="primary"> 사용자</Mark> 정보가 필요하시다면
                                  <br /> <Mark accent="primary"> ||이름||, ||사번||, ||부서명||</Mark> 을 입력해보세요
                                </Text>
                                <Text size="body3" weight="medium">
                                  <Badge variant="filled" size="small" accent="primary" iconOnly="check" round />더
                                  자세한 설명은{' '}
                                  <Mark accent="primary">
                                    <a
                                      href="https://works.ktds.co.kr/group/main/workflowguide?p_p_id=qworkflow&p_p_lifecycle=0&p_p_state=normal&p_p_mode=view&_qworkflow_mvcRenderCommandName=viewWorkflow&_qworkflow_returnURL=%2Fgroup%2Fmain%2Fworkflowguide%3Fp_p_id%3Dqworkflow%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview&_qworkflow_workflowId=99572931"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      가이드
                                    </a>
                                  </Mark>
                                  를 참고해주세요
                                </Text>
                              </div>
                            </>
                          }
                        />
                      </div>
                    </div>{' '}
                    {!isValidJson && <Text variant="error">유효한 JSON을 입력해주세요</Text>}
                    <div className="upload-fileView">
                      <div className="upload-fileView-head">
                        <span className="file-name">JSON</span>
                        <div className="upload-fileView-controls">
                          <IconButton
                            name="download"
                            size="large"
                            onClick={() => {
                              handleJsonDownload(item.frontId);
                            }}
                          >
                            파일다운로드
                          </IconButton>
                          <Button
                            variant="normal"
                            onClick={() => {
                              handleFileReset(item.frontId);
                            }}
                          >
                            초기화
                          </Button>
                        </div>
                      </div>

                      <div className="upload-fileView-code">
                        <MonacoEditor
                          height="400px"
                          value={item.jsonData}
                          onChange={handleJsonDataChange(item.frontId)}
                          options={{
                            selectOnLineNumbers: true,
                            automaticLayout: true,
                            language: 'json',
                            minimap: {
                              enabled: false,
                            },
                            tabSize: 2,
                            wrappingIndent: 'same', // 줄바꿈 들여쓰기 설정
                            wordWrap: 'on', // 자동 줄바꿈 활성화
                            formatOnPaste: true,
                            formatOnType: true,
                            insertSpaces: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </FormItem>
        {/* 250203 : 학습유형 auto 전환으로 감싸는 Mode 파일 추가
      <ButtonArea align="end">
        <div className="dsx-side-left">
          <Button variant="outline" size="large" prefixIcon="chevronLeft" onClick={handlePrev}>
            이전
          </Button>
        </div>
        <Button type="submit" variant="primary" size="large" suffixIcon="chevron" onClick={handleFormSubmit}>
          다음
        </Button>
      </ButtonArea> */}
      </>
    );
  },
);
PluginCreateModeAPI.displayName = 'PluginCreateModeAPI';

export default PluginCreateModeAPI;
